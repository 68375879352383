<template>
  <TsCard no-border :data-testid="`${props.dataTestid}-card`" body-class="py-0 flex flex-col flex-1"
    footer-class="pt-0 flex flex-col mt-auto" :append-class="twMerge(
      'bg-white flex flex-col h-full w-full drop-shadow-[1px_5px_12px_0px_#00152A0F] text-left relative'
    )" :id="props.id || `product-${product?.code}-card-` + random_id">
    <template #header>
      <TsRow v-if="removeProduct" append-class="m-0 justify-between items-center mb-2">
        <div role="status" @click="emit('freeze')" class="!cursor-pointer select-none">
          <TsButton
            :append-class="twMerge('px-6 font-medium py-0.5 outline-none focus:ring-0', isFreezed ? 'bg-primary text-white' : 'bg-white text-primary')"
            size="xs" rounded variant="info" :label="isFreezed ? 'Freezed' : 'Freeze'"
            data-testid="plp-compare-product-freeze-card" />
        </div>
        <TsIcon class="cursor-pointer rounded transition-colors hover:bg-info/10" name="bitcoin-icons:cross-filled"
          size="24" @click="handleClearProduct" data-testid="plp-compare-product-remove-compare-card" />
      </TsRow>

      <TsMedia v-else append-class="items-center mb-2">
        <TsMediaStart>
          <TsTag v-for="(item, index) in topRightTags" :key="item" :label="item" size="xs" rounded variant="info" />
        </TsMediaStart>
        <TsMediaEnd>
          <TsButton v-if="!isSaved" :icon="props.product?.isSaved ? 'solar:heart-bold' : 'solar:heart-linear'"
            :icon-class="props.product?.isSaved ? 'size-6 text-red-600' : 'size-6'" variant="secondary" size="xs"
            rounded append-class="hover:bg-natural-light-grey p-1" @click="handleSavedListClick()" />
        </TsMediaEnd>
      </TsMedia>

      <TsRow append-class="m-0 py-6" isCentered>
        <NuxtLink :to="`/product/${product?.slug}-${product?.code}`"
          data-testid="plp-compare-product-img-redirect-link">
          <NuxtImg :src="product?.image" :width="isMobile ? '105' : '120'" height="105" />
        </NuxtLink>
      </TsRow>

      <TsRow append-class="m-0 items-end">
        <TsTypography append-class="ms-auto" size="xs"><strong>ID: </strong>{{ product?.code }}</TsTypography>
      </TsRow>
    </template>

    <template #content>
      <TsTag v-if="limitedTimeOffer.expiresIn" variant="danger" append-class="mb-3" size="xs" rounded>
        <Icon name="oi:timer" size="12" />{{ limitedTimeOffer.text }}
      </TsTag>

      <NuxtLink class="min-h-12" :to="`/product/${product?.slug}-${product?.code}`" data-testid="plp-compare-product-redirect-link">
        <TsTypography append-class="line-clamp-2 text-natural-black mb-2 hover:underline"><strong>{{ product?.brand
            }}</strong> {{ product?.full_name }}</TsTypography>
      </NuxtLink>
      <TsMedia append-class="flex-1" :append-class="twMerge('items-center gap-2', product_layout === 'mobile-grid' && 'flex-col-reverse items-start',
        props.priceAndRatingsContainerClass)">
        <TsMediaContent>
          <div :class="`product?.prices?.was_price_raw ?? 0 > 0 && h-5`">
            <TsTypography v-if="(product?.prices && product?.prices?.raw && product?.prices?.was_price_formatted)
                && product?.prices?.raw.gross < product?.prices.was_price_raw"
              append-class="line-through leading-tight text-sm">
              {{ product?.prices?.was_price_formatted }}
            </TsTypography>
          </div>
          <TsTypography variant="h2" weight="bold" append-class="text-idle-black leading-normal -mb-2 leading-tight">
            {{ rootStore.isIncVat ? product?.prices?.formatted.gross : product?.prices?.formatted.net }}
            <TsTypography v-if="product?.prices?.formatted.per_unit" as="span" variant="xs"
              append-class="text-natural-silver-grey leading-tight">
              {{ product?.prices?.formatted.per_unit }}
            </TsTypography>
          </TsTypography>
          <TsTypography as="span" variant="xs" append-class="leading-tight">
            {{ rootStore.isIncVat ? 'Excl.' : 'Inc.' }} VAT
            {{ rootStore.isIncVat ? product?.prices?.formatted.net : product?.prices?.formatted.gross }}
          </TsTypography>
        </TsMediaContent>
      </TsMedia>

      <div :class="productReviews && 'h-4'">
        <TsRatings v-if="productReviews" :modelValue="productRatings" :label="`(${productReviews})`" :read-only="true" />
      </div>
    </template>

    <template #footer>


      <div data-testid="plp-compare-product-product-specication" class="px-0 first:border-y-0 py-2">
        <TsRow v-if="isAccordion" append-class="m-0 justify-between">
          <TsTypography append-class="text-sm text-idle-black font-semibold">Technical Specifications</TsTypography>
          <TsIcon :name="compareStore.isSpecificationEnable ? 'fe:arrow-up' : 'fe:arrow-down'" size="22" />
        </TsRow>
        <div v-if="!compareStore.isSpecificationEnable" class="flex flex-col mb-3">
          <div class="border-b py-2" v-for="Specifications in props?.product?.technical_spec_attributes"
            :key="Specifications.id">
            <TsTypography variant="sm" append-class="mb-1">{{ Specifications.key }}</TsTypography>
            <TsTypography variant="sm" weight="semibold" append-class="text-idle-black">{{ Specifications.value }}
            </TsTypography>
          </div>
        </div>
      </div>

      <div v-if="showAddToTrolleyBtn" class="mt-auto space-y-3">
        <TsButton append-class="max-md:px-1" icon="bx:cart" size="sm" block label="Add to trolley"
          data-testid="plp-compare-product-add-item-to-trolley" :disabled="isLoading" :isLoading="isLoading"
          @click="handleTrolleyInit()" />
      </div>

    </template>
  </TsCard>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import { useRandomUUID } from "../../composables/useRandomUUID";
import { data } from "../../json/product.json";
import type { Product } from "~/types/ecom/product/product.type";
import { TrolleyChannel } from "~/types/ecom/trolley/trolley-map";

const emit = defineEmits<{
  freeze: any;
  removeItem: any;
  toggleSavedList: any;
}>();

type Props = {
  product?: Partial<Product> | null;
  appendClass?: string;
  limitedTimeOffer?: {
    text: string;
    expiresIn?: string | Date;
  };
  // Product props
  dataTestid?: string;
  id?: string;
  layout?: "grid" | "list";
  tags?: string[];
  priceAndRatingsContainerClass?: string;
  ratingsContainerClass?: string;
  productImageWidth?: string;
  productImageHeight?: string;
  isFreezed?: boolean;
  isAccordion?: boolean;
  showAddToTrolleyBtn?: boolean;
  removeProduct?: boolean;
  isSaved?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  product: () => data,
  limitedTimeOffer: () => ({
    text: "Limited time deal",
  }),
  appendClass: "",
  priceAndRatingsContainerClass: "",
  ratingsContainerClass: "",
  tags: () => ["New", "20% off"],
  isFreezed: false,
  isAccordion: true,
  showAddToTrolleyBtn: true,
  removeProduct: true
});

const random_id = ref("");
const { isMobile, isDesktop } = useDevice();
const rootStore = useRootStore();
const trolleyStore = useTrolleyStore();
const compareStore = useCompareStore();
const productAttributeStore = useProductAttributeStore();
const isLoading = ref(false);
const savelistStore = useSavelistStore();

onMounted(() => {
  random_id.value = useRandomUUID();
});

async function handleTrolleyInit() {
  const stockStore = useStockStore();
  trolleyStore.preview_overlay_visible = true;

  trolleyStore.previewed_product_selected_quantity = 1;
  trolleyStore.is_added = false;

  trolleyStore.is_updating_item_details_in_add_to_trolley = true;

  trolleyStore.previewed_product = props.product as Product;

  trolleyStore.previewed_product.stockDetails = undefined;

  const [productWithStock] = await stockStore.getStockForProducts([
    props.product?.code as string,
  ]);

  trolleyStore.previewed_product = productWithStock;

  trolleyStore.collection_availability =
      await trolleyStore.evaluateCollectionAvailability(
          trolleyStore.previewed_product
      );

  const { outOfStockForCollection, outOfStockForDelivery } = trolleyStore.previewed_product;
  const isBranchSet = useBranchStore().is_branch_set;

  if(!outOfStockForCollection && isBranchSet && outOfStockForDelivery) 
    trolleyStore.previewed_product_selected_channel = TrolleyChannel.Collection;
  else if(!outOfStockForDelivery)  
    trolleyStore.previewed_product_selected_channel = TrolleyChannel.Delivery;
  else
    trolleyStore.previewed_product_selected_channel = null as any;

  trolleyStore.is_updating_item_details_in_add_to_trolley = false;
}


const product_layout = computed(() => {
  if (isMobile && props.layout === "grid") {
    return `mobile-${props.layout}`;
  } else if (isMobile && props.layout === "list") {
    return `mobile-${props.layout}`;
  } else if (isDesktop && props.layout === "grid") {
    return `desktop-${props.layout}`;
  } else if (isDesktop && props.layout === "list") {
    return `desktop-${props.layout}`;
  }
  return "";
});

const productReviews = ref(
  productAttributeStore.getNumberOfReviews(props.product?.other_attributes!)
);

function convertToNearestWholeNumber(num: number) {
  const firstDecimalPlace = Math.floor(num * 10) % 10;
  if (firstDecimalPlace < 5) {
    return Math.floor(num);
  } else {
    return Math.ceil(num);
  }
}

function handleClearProduct() {
  if (compareStore.checkedProduts.length === 1) compareStore.isCompareRowVisible = false;
  if (compareStore.freezedProductCode && compareStore.freezedProductCode === props.product?.code) {
    compareStore.freezedProductCode = undefined;
  }
  emit('removeItem', props.product);
}

const productRatings = ref(
  convertToNearestWholeNumber(
    Number(
      productAttributeStore.getProductRating(props.product?.other_attributes!)
    )
  )
);

const handleSavedListClick = () => {
  const authStore = useAuthStore();
  if (!authStore.is_authenticated) {
    navigateTo("/auth/signin");
  } else {
    emit("toggleSavedList");
    savelistStore.modal_savelist_from_visible = true;
    if (!savelistStore.fetching_in_progress) {
      savelistStore.getUserSaveLists();
    }
    savelistStore.selectedSavelist = [];
    if (props.product !== null) {
      savelistStore.current_active_product = props.product as Product;
    }
    savelistStore.populateSavedList();
  }
};

const topRightTags = productAttributeStore.getTopRightTagValues(
  props.product?.other_attributes!
);

</script>
